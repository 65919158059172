import React from 'react'
import BGTest from "../../assets/img/bg/testimonial-bg.png";
import avatar1 from "../../assets/img/testimonial/testi_avatar.png"
import avatar2 from "../../assets/img/testimonial/testi_avatar_02.png" 
import avatar3 from "../../assets/img/testimonial/testi_avatar_03.png"
import Aicon from "../../assets/img/testimonial/qt-icon.png"
import Aicon1 from "../../assets/img/testimonial/review-icon.png"
import Slider from "react-slick"

function Testimonial() {

    const testimonial = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]}

  return (
    <>
        <section className="testimonial-area pt-120 pb-90 p-relative fix" style={{backgroundImage: `url(${BGTest})`, background_size: "cover"}} >
                <div className="container">
                    <div className="row">
                         <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                <h5>Testimonial</h5>
                                <h2>What Our Guests Says</h2>
                                <p>Guests Share Their Unforgettable Experiences: Discover the Magic and Memories Crafted at SthitpragyaB&B</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Slider className="testimonial-active" {...testimonial}>
                                <div className="single-testimonial">
                                     <div className="testi-author">
                                        <img src={avatar1} alt="img"/>
                                        <div className="ta-info">
                                            <h6>Vikram Singha</h6>
                                            <span>Guest</span>
                                        </div>
                                    </div>
                                    <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                     </div>
                                    <p>“My recent stay at SthitpragyaB&B was nothing short of spectacular. From the moment I arrived, I was greeted with warm hospitality and genuine kindness from the staff. The homestay itself is a hidden gem nestled amidst the serene beauty of Shimla.”.</p>
                                    
                                    <div className="qt-img">
                                    <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                                <div className="single-testimonial">
                                        <div className="testi-author">
                                            <img src={avatar1} alt="img"/>
                                            <div className="ta-info">
                                                <h6>Varun Chauhan</h6>
                                                <span>Guest</span>
                                            </div>
                                        </div>
                                    <div className="review-icon">
                                            <img src={Aicon1} alt="img"/>
                                        </div>
                                        <p>“The accommodations were beyond luxurious – spacious, elegantly furnished, and equipped with all the modern amenities one could ask for. The attention to detail was impeccable, and every aspect of my room exuded comfort and sophistication.”.</p>
                                        
                                        <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                        </div>
                                    </div>
                                <div className="single-testimonial">
                                        <div className="testi-author">
                                            <img src={avatar1} alt="img"/>
                                            <div className="ta-info">
                                                <h6>Rohit Shandil</h6>
                                                <span>Guest</span>
                                            </div>
                                        </div>
                                        <div className="review-icon">
                                            <img src={Aicon1} alt="img"/>
                                        </div>
                                        <p>“What truly made my stay memorable was the exceptional level of service. The staff went above and beyond to ensure that every aspect of my visit exceeded expectations. Whether it was arranging personalized excursions or attending to my every need, their attention to detail was second to none.”.</p>
                                        
                                        <div className="qt-img">
                                        <img src={Aicon} alt="img"/>
                                        </div>
                                    </div>
                                <div className="single-testimonial">
                                     <div className="testi-author">
                                        <img src={avatar1} alt="img"/>
                                        <div className="ta-info">
                                            <h6>Monica Oberoi</h6>
                                            <span>Guest</span>
                                        </div>
                                    </div>
                                   <div className="review-icon">
                                        <img src={Aicon1} alt="img"/>
                                     </div>
                                      <p>“Our family recently had the pleasure of staying at SthitpragyaB&B, and it was truly a remarkable experience. From the moment we arrived, we were greeted with warmth and hospitality that made us feel right at home. The accommodations were simply stunning, providing a perfect blend of luxury and comfort for our stay.”.</p>
                                    
                                    <div className="qt-img">
                                    <img src={Aicon} alt="img"/>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default Testimonial