import React from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { MdPhoneInTalk } from "react-icons/md";
import { MdOutlineAlternateEmail } from "react-icons/md";
import Hotel from "../../assets/img/bg/an-img-01.png"
import Booking from "../../assets/img/bg/booking-img.png"


function Book() {
  return (
    <>
        <section className="booking pt-120 pb-120 p-relative fix">
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                             <div className="contact-bg02">
                                <div className="section-title center-align">
                                    <h5>Our Location</h5>
                                    <h2>
                                        Your Gateway to Serenity
                                    </h2>
                                </div>                                
                                {/* <form action="mail.php" method="post" className="contact-form mt-30">
                                    <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-field p-relative c-name mb-20">                                    
                                           <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                            <input type="date" id="chackin2" name="date" />
                                        </div>                               
                                    </div>

                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                           <label><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                            <input type="date" id="chackout2" name="date" />
                                        </div>
                                    </div>		
                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <label><i className="fal fa-users"></i> Adults</label>
                                            <select name="adults" id="adu2">
                                                <option value="sports-massage">Adults</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>	
                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-option mb-20">                                   
                                            <label><i className="fal fa-concierge-bell"></i> Room</label>
                                            <select name="room" id="rm2">
                                                <option value="sports-massage">Room</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="slider-btn mt-15">                                          
                                            <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s"><span>Book Table Now</span></button>				
                                        </div>                             
                                    </div>
                                </div>
                            </form>   
                                                      */}
                                <div class="address-wrap mt-5">
                                    <div className='icon-circle'><IoLocationSharp className='size-26' title='Location' /></div>
                                        <p>Address: C/O: Rohit Shandil, Flat 2nd, 2 Floor 
                                        Ashoka Bhawan,<br/> Lok Tara Estate,Near Dingle Lodge, Kaithu, Shimla Urban,<br/> 
                                        Shimla, Himachal Pradesh, 171003</p>
                                </div>
                                <div class="address-wrap mt-3">
                                    <div className='icon-circle'><MdPhoneInTalk className='size-26' title='Location' /></div>
                                        <p>+91 88940 55400</p>
                                </div>
                                <div class="address-wrap mt-3">
                                    <div className='icon-circle'><MdOutlineAlternateEmail className='size-26' title='Location' /></div>
                                        <p>contact@sthitpragyabnb.com</p>
                                </div>
                            </div>  
                                             
                        </div>
                        <div className="col-lg-6 col-md-6">
                             <div className="booking-img">
                                
                                 <iframe     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1819.147593672172!2d77.16402145808253!3d31.10673749160009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390578eef1a3b14d%3A0xb4e54b47d19040f!2sVictory%20Tunnel%2C%20Shilli%2C%20Shimla%2C%20Himachal%20Pradesh%20171001!5e0!3m2!1sen!2sin!4v1712523875313!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default Book