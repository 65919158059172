import React from 'react'
import { Link } from 'react-router-dom'
import Resort from "../../assets/img/bg/an-img-02.png"
import Resort1 from "../../assets/img/features/feature.png"

function Luxury() {
  return (
    <>
        <section className="feature-area2 p-relative fix" style={{background: "#f7f5f1"}}>
                 <div className="animations-02"><img src={Resort} alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                         <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                           <div className="feature-img">                               
                                <img src={Resort1} alt="img" className="img"/>              
                            </div>
                        </div>
					   <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="feature-content s-about-content">
                                <div className="feature-title pb-20">                               
                                    <h5>Luxury B&B</h5>
                                <h2>
                                Experience Our Exclusive Homestay Retreat
                                </h2>                             
                                </div>
                                <p>At our luxury homestay, personalized service is our commitment. Our dedicated team of hospitality professionals is on hand to anticipate your every need, from arranging bespoke experiences to providing insider recommendations on local attractions and activities. Experience the epitome of hospitality as we cater to your individual preferences with warmth and attention to detail.</p>
                                <div className="slider-btn mt-15">                                          
                                    <Link to="https://www.airbnb.com.au/rooms/1069573205916634557?source_impression_id=p3_1712510066_bH%2BweDtXlDjqX2D7" target="_blank"  className="btn ss-btn smoth-scroll">Book Now</Link>				
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default Luxury