import React , { useState } from 'react'
import { FaKitchenSet } from "react-icons/fa6";
import { GiWashingMachine } from "react-icons/gi";
import { MdOutlineBalcony } from "react-icons/md";
import { PiTelevisionBold } from "react-icons/pi";
import { TbAirConditioning } from "react-icons/tb";
import { FaWifi } from "react-icons/fa";
import { FaLaptopHouse } from "react-icons/fa";
import icon1 from "../../assets/img/icon/sve-icon1.png"
import icon2 from "../../assets/img/icon/sve-icon2.png"
import icon3 from "../../assets/img/icon/sve-icon3.png"
import icon4 from "../../assets/img/icon/sve-icon4.png"
import icon5 from "../../assets/img/icon/sve-icon5.png"
import icon6 from "../../assets/img/icon/sve-icon6.png"
import service1 from "../../assets/img/gallery/room-img01.png"
import service2 from "../../assets/img/gallery/room-img02.png"
import service3 from "../../assets/img/gallery/room_4.png"
import service4 from "../../assets/img/gallery/room_5.png"
import Slider from "react-slick"
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react';


function Rooms() {

    const [toggler, setToggler] = useState(false);
    const [activeImage , setActiveImage] = useState(1)
    const [images , setImages] = useState([
        '../..assets/img/gallery/room-img01.png',
        '../..assets/img/gallery/room-img02.png',
        '../..assets/img/gallery/room_4.png',
        '../..assets/img/gallery/room_5.png',
        '../..assets/img/gallery/room-img05.png',
        '../..assets/img/gallery/room-img06.png'
	])


    const service = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

  return (
    <>
        <section id="services" className="services-area pt-113 pb-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">    
                            <div className="section-title center-align mb-50 text-center">
                                <h5>The pleasure of luxury</h5>
                                <h2>Rooms in BnB</h2>
                                <p>Whether you're traveling solo, as a couple, or with family and friends, we offer a variety of room options to suit your preferences. From cozy single rooms perfect for solo travelers to spacious suites ideal for romantic getaways or family vacations, we have the perfect accommodation for every occasion.</p>
                            </div>
                        </div>
                    </div>
                    <Slider className="row services-active" {...service}> 
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler)
                                    setActiveImage(1)
                                }}>
                                    <img src={service1} alt="img" />
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <div className="day-book">
                                        <ul>
                                            <li>₹ 2,999/Night</li>
                                            <li><Link to="https://www.airbnb.com.au/rooms/1069573205916634557?source_impression_id=p3_1712510066_bH%2BweDtXlDjqX2D7"  target="_blank">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/room-details">Delux Room Per Night</Link></h4>    
                                    <p>Relax and unwind in the cozy seating area, perfect for enjoying a morning cup of coffee or curling up with a good book in the afternoon sunlight streaming through the windows</p>
                                    <div className="icon">
                                        <ul>
                                        <li><FaKitchenSet className='size-26' title='Kitchen' /></li>
                                        <li><GiWashingMachine className='size-26' title='Washing Machine' /></li>
                                        <li><FaWifi className='size-26' title='Wi-Fi' /></li>
                                        <li><MdOutlineBalcony className='size-26' title='Balcony' /></li>
                                        <li><PiTelevisionBold className='size-26' title='Television' /></li>
                                        <li><TbAirConditioning className='size-26' title='Air conditioning' /></li>
                                        <li><FaLaptopHouse className='size-26' title='Dedicated workspace' /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler)
                                    setActiveImage(2)
                                }}>
                                    <img src={service2} alt="img"/>
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <div className="day-book">
                                        <ul>
                                            <li>₹ 4,000/Night</li>
                                            <li><Link to="https://www.airbnb.com.au/rooms/1069573205916634557?source_impression_id=p3_1712510066_bH%2BweDtXlDjqX2D7"  target="_blank">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/room-details">2 Rooms Per Night</Link></h4>    
                                    <p>Relax and unwind in the cozy seating area, perfect for enjoying a morning cup of coffee or curling up with a good book in the afternoon sunlight streaming through the windows</p>
                                    <div className="icon">
                                        <ul>
                                        <li><FaKitchenSet className='size-26' title='Kitchen' /></li>
                                        <li><GiWashingMachine className='size-26' title='Washing Machine' /></li>
                                        <li><FaWifi className='size-26' title='Wi-Fi' /></li>
                                        <li><MdOutlineBalcony className='size-26' title='Balcony' /></li>
                                        <li><PiTelevisionBold className='size-26' title='Television' /></li>
                                        <li><TbAirConditioning className='size-26' title='Air conditioning' /></li>
                                        <li><FaLaptopHouse className='size-26' title='Dedicated workspace' /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler)
                                    setActiveImage(3)
                                }} >
                                    <img src={service3} alt="img"/>
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <div className="day-book">
                                        <ul>
                                            <li>₹ 5,999/Night</li>
                                            <li><Link to="https://www.airbnb.com.au/rooms/1069573205916634557?source_impression_id=p3_1712510066_bH%2BweDtXlDjqX2D7"  target="_blank">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/room-details">3 Rooms Per Night</Link></h4>    
                                    <p>Relax and unwind in the cozy seating area, perfect for enjoying a morning cup of coffee or curling up with a good book in the afternoon sunlight streaming through the windows</p>
                                    <div className="icon">
                                        <ul>
                                        <li><FaKitchenSet className='size-26' title='Kitchen' /></li>
                                        <li><GiWashingMachine className='size-26' title='Washing Machine' /></li>
                                        <li><FaWifi className='size-26' title='Wi-Fi' /></li>
                                        <li><MdOutlineBalcony className='size-26' title='Balcony' /></li>
                                        <li><PiTelevisionBold className='size-26' title='Television' /></li>
                                        <li><TbAirConditioning className='size-26' title='Air conditioning' /></li>
                                        <li><FaLaptopHouse className='size-26' title='Dedicated workspace' /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler)
                                    setActiveImage(4)
                                }}>
                                    <img src={service4} alt="img"/>
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <div className="day-book">
                                        <ul>
                                            <li>₹ 8,000/Night</li>
                                            <li><Link to="https://www.airbnb.com.au/rooms/1069573205916634557?source_impression_id=p3_1712510066_bH%2BweDtXlDjqX2D7"  target="_blank">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/room-details">Whole BnB Per Night</Link></h4>    
                                    <p>Relax and unwind in the cozy seating area, perfect for enjoying a morning cup of coffee or curling up with a good book in the afternoon sunlight streaming through the windows</p>
                                   <div className="icon">
                                        <ul>
                                        <li><FaKitchenSet className='size-26' title='Kitchen' /></li>
                                        <li><GiWashingMachine className='size-26' title='Washing Machine' /></li>
                                        <li><FaWifi className='size-26' title='Wi-Fi' /></li>
                                        <li><MdOutlineBalcony className='size-26' title='Balcony' /></li>
                                        <li><PiTelevisionBold className='size-26' title='Television' /></li>
                                        <li><TbAirConditioning className='size-26' title='Air conditioning' /></li>
                                        <li><FaLaptopHouse className='size-26' title='Dedicated workspace' /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                </div>
        </section>

        <FsLightbox
            toggler={toggler}
            sources={images}
            slide={activeImage}
        />
    </>
  )
}

export default Rooms