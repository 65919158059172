import React from 'react'
import { Link } from 'react-router-dom'
import SimpleSlider from './SliderRoomTwo';
function First() {
  return (
    <>
        <section id="services" className="services-area pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-auto">
                            <div className="single-services ser-m mb-30">
                                <SimpleSlider />
                                <div className="services-content">
                                    <div className="day-book">
                                        <ul>
                                            <li>₹ 7,999/Night</li>
                                            <li><Link to="/contact">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    <h4>Whole B&B</h4>
                                    <p>Our single room offers a comfortable and intimate space for solo travelers seeking a peaceful retreat during their stay. Sthitpragyabnb provides a warm and inviting atmosphere, ensuring a memorable experience for our guests.</p>
                                    <p>Designed with your comfort in mind, our single room features a comfortable single bed adorned with soft linens, ensuring a restful night's sleep after a day of exploring the charms of Shimla. The room is tastefully decorated with elegant furnishings and modern amenities to cater to your needs.</p>
                                    <p>As our guest, you'll enjoy complimentary Wi-Fi access, allowing you to stay connected with loved ones or catch up on work during your stay. </p>
                                    <p>At Sthitpragyabnb, we strive to provide exceptional hospitality and personalized service to ensure your stay with us is nothing short of perfect. our single room offers the ideal haven for a memorable stay.</p>

                                    <p>Book your stay with us today and experience the comfort and convenience of our single room at Sthitpragyabnb!</p>
                                    <div className="icon">
                                        {/* <ul>
                                            <li><img src="assets/img/icon/sve-icon1.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon2.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon3.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon4.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon5.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon6.png" alt="img" /></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default First