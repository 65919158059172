import React from 'react'
import { FaKitchenSet } from "react-icons/fa6";
import { GiWashingMachine } from "react-icons/gi";
import { MdOutlineBalcony } from "react-icons/md";
import { PiTelevisionBold } from "react-icons/pi";
import { TbAirConditioning } from "react-icons/tb";
import { GiRoundTable } from "react-icons/gi";
import { IoBedOutline } from "react-icons/io5";
import { LuSofa } from "react-icons/lu";
import { FaWifi } from "react-icons/fa";
import { FaUmbrellaBeach } from "react-icons/fa";
import { FaLaptopHouse } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { FaBath } from "react-icons/fa";
import Hotel from "../../assets/img/bg/an-img-01.png"
import pricing1 from "../../assets/img/bg/an-img-02.png"

function Prices() {
  return (
    <>
        <section id="pricing" className="pricing-area pt-120 pb-60 fix p-relative">
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="animations-02"><img src={pricing1} alt="contact-bg-an-01"/></div>
                <div className="container"> 
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4 col-md-12">
                            <div className="section-title mb-20">
                                <h5>Indulge in Our Exceptional Services</h5>
                                <h2>Our Services</h2>                               
                            </div>
                            <p>At our luxury homestay, we pride ourselves on providing a range of exceptional services to ensure your stay is nothing short of extraordinary. From the moment you arrive, our dedicated team is committed to catering to your every need, creating a seamless and unforgettable experience</p>
                            <p>Whether you're celebrating a birthday, anniversary, or other special occasion, let us help you create unforgettable memories. From arranging romantic dinners to organizing surprise celebrations, we'll ensure your special day is nothing short of magical.</p>  
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">  
                                    <h3>Delux Room</h3>    
                                    <p>Experience Our Deluxe Room</p>
                                    <div className="month">Per Night</div>
                                    <div className="price-count">
                                        <h2>₹2,999</h2>
                                    </div> 
                                    <hr/>
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <ul>
                                        <li className='service-items'><FaKitchenSet className='size-26' title='Kitchen' /> Kitchen</li>
                                        <li className='service-items'><GiWashingMachine className='size-26' title='Washing Machine' /> Washing Machine</li>
                                        <li className='service-items'><FaWifi className='size-26' title='Wi-Fi' /> Wi - Fi</li>  
                                        <li className='service-items'><MdOutlineBalcony className='size-26' title='Balcony' />  Balcony</li>
                                        <li className='service-items'><PiTelevisionBold className='size-26' title='Television' /> Television</li>
                                        <li className='service-items'><TbAirConditioning className='size-26' title='Air conditioning'/> Air Conditioning</li>                                          
                                    </ul>
                                </div>  
                                <div className="pricing-btn">
                                    <Link to="/contact" className="btn ss-btn">Know More About Services <i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                         <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head">  
                                    <h3>Whole B&B </h3>    
                                    <p>Step into luxury and comfort</p>
                                    <div className="month">Per Night</div>
                                    <div className="price-count">
                                        <h2>₹7,999</h2>
                                    </div> 
                                    <hr/>
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <ul>
                                    <li className='service-items'><IoBedOutline className='size-26' title='4 Bed Rooms' /> 4 Bed Rooms</li>
                                        <li className='service-items'><FaBath className='size-26' title='4 Bathrooms' /> 4 Bathrooms</li>
                                        <li className='service-items'><GiRoundTable className='size-26' title='1 Dining Room' />  1 Dining Room</li>
                                        <li className='service-items'><LuSofa className='size-26' title='2 Living Rooms' />2 Living Rooms</li>
                                        <li className='service-items'><FaUmbrellaBeach className='size-26' title='Terrace ' /> Terrace </li>
                                        <li className='service-items'><MdOutlineBalcony className='size-26' title='Balcony' /> Balcony</li>                                    
                                    </ul>
                                </div> 
                                <div className="pricing-btn">
                                <Link to="/contact" className="btn ss-btn">Know More About Services <i className="fa-solid fa-arrow-right-long"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default Prices