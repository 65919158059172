import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Room_1 from '../../../assets/img/room/room_1.png';
// import Room_2 from '../../../assets/img/room/room_2.png';
// import Room_3 from '../../../assets/img/room/room_3.png';
import Room_4 from '../../../assets/img/room/room_4.png';
import Room_5 from '../../../assets/img/room/room_5.png';
import Room_6 from '../../../assets/img/room/room_6.png';
import Room_7 from '../../../assets/img/room/room_7.png';

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

 
    
  return (
    <Slider {...settings}>
      <div className="room-images-slde">
        <img src={Room_1}/>
      </div>
      {/* <div className="room-images-slde">
      <img src={Room_2}/>
      </div>
      <div className="room-images-slde">
      <img src={Room_3}/>
      </div> */}
      <div className="room-images-slde">
      <img src={Room_4}/>
      </div>
      <div className="room-images-slde">
      <img src={Room_5}/>
      </div>
      <div className="room-images-slde">
      <img src={Room_6}/>
      </div>
      <div className="room-images-slde">
      <img src={Room_7}/>
      </div>
    </Slider>
  );
}