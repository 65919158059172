import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../../Menrooms/Menroomdeatils/First';

const Main = () => {
  return (
    <>
        <main>
            <Bredcom title={"Whole B&B"} subtitle={"Home"} newtitle={"Whole B&B"} />
            <First/>
        </main>
    </>
  )
}

export default Main