import React from 'react'
import { Link } from 'react-router-dom'
import BGImg from "../../assets/img/bg/footer-bg.png";
import Logo from "../../assets/img/logo/s-logo.png";

const Main = () => {
  return (
    <>
        <footer className="footer-bg footer-p">
            <div className="footer-top  pt-90 pb-40" style={{background_color: "#644222", backgroundImage: `url(${BGImg})`}}>
                <div className="container">
                    <div className="row justify-content-between">                          
                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title mb-30">
                                <div className="logo" style={{ backgroundImage: `url(${Logo})`}}>
                                    </div>
                                  
                                </div>
                                <div className="f-contact">
                                    <ul>
                                        <li>
                                        <i class="fa-solid fa-phone"></i>
                                            <span>+91 88940 55400</span>
                                        </li>
                                        <li>
                                            <i class="fa-solid fa-envelope"></i>
                                            <span>
                                                <Link to="#">contact@sthitpragyabnb.com</Link><br/>
                                            </span>
                                        </li>
                                        <li>
                                        <i class="fa-solid fa-location-dot"></i>
                                            <span>Address: C/O: Rohit Shandil, Flat 2nd
                                            2 Floor Ashoka Bhawan, Lok Tara Estate, Near Dingle Lodge, Kaithu, Shimla Urban, Shimla, Himachal Pradesh, 171003</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
						<div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Our Links</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>                                        
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about"> About Us</Link></li>
                                        <li><Link to="/services"> Services </Link></li>
                                        <li><Link to="/contact"> Contact Us</Link></li>
                                        <li><Link to="/blog">Blog </Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Our Services</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li><Link to="/faq">FAQ</Link></li>
                                        <li><Link to="#">Support</Link></li>
                                        <li><Link to="#">Privercy</Link></li>
                                        <li><Link to="#">Term & Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>  
                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                  <h2>Subscribe To Our Newsletter</h2>
                                </div>
                                <div className="footer-link">
                                <p>Subscribe our newsletter to get our latest update &amp; News</p>
                                    <div className="subricbe p-relative" data-animation="fadeInDown" data-delay=".4s" >
                                        <form action="news-mail.php" method="post" className="contact-form ">
                                            <input type="text" id="email2" name="email2"  className="header-input" placeholder="Your Email..." required />
                                            <button className="btn header-btn"> <i className="fas fa-location-arrow"></i> </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">                         
                           Copyright &copy; sthitpragyabnb 2024 . All rights reserved.         
                        </div>
                        <div className="col-lg-6 col-md-6 text-right text-xl-right">                       
                           <div className="footer-social">                                    
                                <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Main
