import React from 'react'
import { Link } from 'react-router-dom'
import Hotel from "../../assets/img/bg/an-img-01.png"
import Hotel1 from "../../assets/img/icon/fe-icon01.png"
import Hotel2 from "../../assets/img/icon/fe-icon04.png"
import Hotel3 from "../../assets/img/icon/fe-icon05.png"
import Hotel4 from "../../assets/img/icon/fe-icon06.png"
import Hotel5 from "../../assets/img/icon/fe-icon07.png"
import Hotel6 from "../../assets/img/icon/fe-icon08.png"

function Explore() {
  return (
    <>
        <section id="service-details2" className="pt-120 pb-90 p-relative" style={{background: "#f7f5f1"}}>
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="section-title center-align mb-50 text-center">
                                <h5>Explore</h5>
                                <h2>
                                    The SthitpragyaBnB Homestay
                                </h2>
                                <p>At SthitpragyaBnB Homestay, every moment is an opportunity to explore, discover, and connect with the beauty of the world around you. Come, embark on an unforgettable journey with us and experience the true essence of hospitality amidst the wonders of nature.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                       <img src={Hotel1} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel1} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details"> Qulity Room</Link></h3>
                                        <p>Designed with your comfort in mind, our quality rooms offer a harmonious blend of modern amenities and traditional charm, providing the perfect sanctuary for your stay.</p>
                                        <Link to="/service-details">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                                    </div>
                                </div>
                            </div>
                              <div className="col-lg-4 col-md-6">                                  
                                <div className="services-08-item mb-30">
                                     <div className="services-icon2">
                                       <img src={Hotel2} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                   <img src={Hotel2} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Scenic View</Link></h3>
                                       <p>Wake up to the gentle glow of the sunrise casting its golden hues over the rolling hills or shimmering waters that stretch as far as the eye can see. Each of our accommodations is strategically positioned to offer panoramic views of the picturesque scenery. </p>
                                         <Link to="/service-details">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                                    </div>
                                </div>
                            </div>
                             <div className="col-lg-4 col-md-6">
                               <div className="services-08-item mb-30">
                                     <div className="services-icon2">
                                       <img src={Hotel3} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel3} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Best Accommodation</Link></h3>
                                        <p>Designed to exceed your expectations and cater to your every need, our best accommodations are the epitome of refinement and sophistication, ensuring a truly unforgettable experience from the moment you arrive.</p>
                                         <Link to="/service-details">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                                    </div>
                                </div>
                            </div>
                         {/* <div className="col-lg-4 col-md-6">
                               <div className="services-08-item mb-30">
                                     <div className="services-icon2">
                                       <img src={Hotel4} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel4} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details"> Wellness & Spa</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                        <Link to="/service-details">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                                    </div>
                                </div>
                            </div>
                              <div className="col-lg-4 col-md-6">
                               <div className="services-08-item mb-30">
                                   <div className="services-icon2">
                                       <img src={Hotel5} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                   <img src={Hotel5} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Restaurants & Bars</Link></h3>
                                       <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                         <Link to="/service-details">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                                    </div>
                                </div>
                            </div>
                             <div className="col-lg-4 col-md-6">
                                <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                       <img src={Hotel6} alt="img"/>
                                    </div>
                                    <div className="services-08-thumb">
                                     <img src={Hotel6} alt="img"/>
                                    </div>
                                    <div className="services-08-content">
                                        <h3><Link to="/service-details">Special Offers</Link></h3>
                                        <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                         <Link to="/service-details">Read More <i className="fa-solid fa-arrow-right-long"></i></Link>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
        </section>
    </>
  )
}

export default Explore