import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Bnb_1 from '../../../assets/img/room/sthitpragyabnb_1.png';
// import Bnb_2 from '../../../assets/img/room/sthitpragyabnb_2.png';
// import Bnb_3 from '../../../assets/img/room/sthitpragyabnb_3.png';
import Bnb_4 from '../../../assets/img/room/sthitpragyabnb_4.png';
import Bnb_5 from '../../../assets/img/room/sthitpragyabnb_5.png';
import Bnb_6 from '../../../assets/img/room/sthitpragyabnb_6.png';
import Bnb_7 from '../../../assets/img/room/sthitpragyabnb_7.png';
import Bnb_8 from '../../../assets/img/room/sthitpragyabnb_8.png';
import Bnb_9 from '../../../assets/img/room/sthitpragyabnb_9.png';
import Bnb_10 from '../../../assets/img/room/sthitpragyabnb_10.png';
import Bnb_11 from '../../../assets/img/room/sthitpragyabnb_11.png';
import Bnb_12 from '../../../assets/img/room/sthitpragyabnb_12.png';
import Bnb_13 from '../../../assets/img/room/sthitpragyabnb_13.png';
import Bnb_14 from '../../../assets/img/room/sthitpragyabnb_14.png';
import Bnb_15 from '../../../assets/img/room/sthitpragyabnb_15.png';
import Bnb_16 from '../../../assets/img/room/sthitpragyabnb_16.png';
import Bnb_17 from '../../../assets/img/room/sthitpragyabnb_17.png';
import Bnb_18 from '../../../assets/img/room/sthitpragyabnb_18.png';
import Bnb_19 from '../../../assets/img/room/sthitpragyabnb_19.png';
import Bnb_20 from '../../../assets/img/room/sthitpragyabnb_20.png';
import Bnb_21 from '../../../assets/img/room/sthitpragyabnb_21.png';
import Bnb_22 from '../../../assets/img/room/sthitpragyabnb_22.png';
import Bnb_23 from '../../../assets/img/room/sthitpragyabnb_23.png';
// import Bnb_24 from '../../../assets/img/room/sthitpragyabnb_24.png';
// import Bnb_25 from '../../../assets/img/room/sthitpragyabnb_25.png';
import Bnb_26 from '../../../assets/img/room/sthitpragyabnb_26.png';
import Bnb_27 from '../../../assets/img/room/sthitpragyabnb_27.png';
import Bnb_28 from '../../../assets/img/room/sthitpragyabnb_28.png';
import Bnb_29 from '../../../assets/img/room/sthitpragyabnb_29.png';
import Bnb_30 from '../../../assets/img/room/sthitpragyabnb_30.png';
import Bnb_31 from '../../../assets/img/room/sthitpragyabnb_31.png';


export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

 
    
  return (
    <Slider {...settings}>
      <div className="room-images-slde">
        <img src={Bnb_1}/>
      </div>
      {/* <div className="room-images-slde">
        <img src={Bnb_2}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_3}/>
      </div> */}
      <div className="room-images-slde">
        <img src={Bnb_4}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_5}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_6}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_7}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_8}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_9}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_10}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_11}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_12}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_13}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_14}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_15}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_16}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_17}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_18}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_19}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_20}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_21}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_22}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_23}/>
      </div>
      {/* <div className="room-images-slde">
        <img src={Bnb_24}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_25}/>
      </div> */}
      <div className="room-images-slde">
        <img src={Bnb_26}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_27}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_28}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_29}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_30}/>
      </div>
      <div className="room-images-slde">
        <img src={Bnb_31}/>
      </div>
      
    </Slider>
  );
}